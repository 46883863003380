//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SigninForm from '~/components/auth/SigninForm'

export default {
  auth: 'guest',
  nuxtI18n: {
    paths: {
      en: '/sign-in',
      fr: '/s-identifier',
    }
  },
  components: {
    SigninForm
  },
  computed: {
    signupUrl () {
      return this.localePath('/en/sign-up')
    },
  }
}
